/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
            linkedin
            instagram
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `flex`,
        marginBottom: rhythm(2.5),
      }}
      className="h-card"
    >
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author}
        placeholderClassName="u-photo"
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          minWidth: 80,
          minHeight: 80,
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      
        <p class="p-note"><strong class="p-name">{author}</strong> is an internationally renowned speaker, teacher, and author on all things accessibility and inclusive design.</p>
        <p>
        Engage with <a rel="me author" class="u-url" href={`https://twitter.com/${social.twitter}`}>
          Derek on Twitter
        </a>, or connect with <a href={`https://linkedin.com/in/${social.linkedin}`}>Derek on LinkedIn</a>, or check out <a href={`https://instagram.com/${social.instagram}`}>Derek's instagram photos</a>.
      </p>
    </div>
  )
}

export default Bio
