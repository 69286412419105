import React from "react"
import { Link } from "gatsby"
import SiteNav from "../components/sitenav"
import Bio from "../components/bio"
import FooterNav from "../components/footerNav"
import Logo from "../../content/assets/feather-logo.png"
import "./layout.css"


import { rhythm, scale } from "../utils/typography"

class Layout extends React.Component {
  render() {
    const { menuLinks, location, title, tagline, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`

    let layoutFlag

    let header

    if (location.pathname === rootPath) {
      layoutFlag = "index"
      header = (
        <React.Fragment>
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            <img className="logo" src={Logo} alt=""  />
          </Link>
          
          <h1
          style={{
            marginBottom: rhythm(1.2),
            marginTop: 0,
          }}
        >
            {title}
            <span 
            style={{
            display: `block`,
            fontWeight: `300`
            
          }}>{tagline}</span>
        </h1>
        
          <SiteNav menuLinks={menuLinks} />
        </React.Fragment>

       
      )
    } else {
      header = (
        <React.Fragment>
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            <img className="logo" src={Logo} alt="" />
          </Link>

        <h3>
            {title}  
            <span
          style={{
            display: `block`,
            fontWeight: `300`
            
          }}>{tagline}</span>        
        </h3>
       

        <SiteNav menuLinks={menuLinks} />

        </React.Fragment>
      )
    }
    return (
      <div
        className={layoutFlag}
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(28),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>{header}</header>
        <main>{children}</main>
        <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
        <footer>
          <Bio />
          <FooterNav />
        </footer>
      </div>
    )
  }
}

export default Layout

