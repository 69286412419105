import Typography from "typography"

/*Wordpress2016.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
  }
}
*/


const typography = new Typography(
  {
         baseFontSize: '18px',
         baseLineHeight: 1.6,
         headerFontFamily: ['Lato', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', 'sans-serif'],
         headerWeight: 700,
         bodyFontFamily: ['Lato','Arial', 'serif'],
         bodyWeight: 400
     }
)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
