import React from "react"

const footerNav = () => {
    return (
        <div>
            <p>Handy links: <a href="/rss.xml">RSS</a>, <a href="/privacy">Privacy</a></p>
            <p><small>Copyright 2020. feather.ca is part of 8038635 Canada Inc.</small></p>
        </div>
    )
    
}

export default footerNav
