import React from "react"
import { Link } from "gatsby"

class SiteNav extends React.Component {
    render() {
        const { menuLinks } = this.props

        return (
        
            <nav>
            <ul
            style={{
              marginLeft: `0`
            }}
            >
            
            {menuLinks.map(link => (
                <li
                  key={link.name}
                  style={{
                    listStyleType: `none`,
                    paddingRight: `1rem`,
                    display: `inline`
                  }}
                >
                  <Link to={link.link}>
                    {link.name}
                  </Link>
                </li>
              ))}

   
            </ul>
            </nav>
        
        
        
        
        )

    }
}

export default SiteNav

